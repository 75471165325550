.separator {
    display: flex;
    justify-content: center;
    margin-bottom: var(--space-32);
    border-bottom: 1px solid var(--color-neutral-200);
}

.separatorWording {
    padding: var(--space-4) var(--space-16);
    transform: translateY(50%);
    background-color: var(--color-neutral-0);
    color: var(--color-neutral-400);
}
