.modalWrapper {
    display: flex;
    z-index: var(--z-index-modal);
    position: fixed;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    inset: 0;

    --screen-padding: var(--space-24);
    --border-radius: 16px;
    --modal-small-width: 640px;
    --modal-large-width: 960px;
    --modal-content-horizontal-padding: var(--space-16);
}

.overlay {
    position: absolute;
    inset: 0;
    background-color: var(--color-neutral-900-60);
}

.small.modalWrapper,
.large.modalWrapper {
    padding-top: var(--screen-padding);
}

.modal {
    position: relative;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
}

.small .modal,
.large .modal {
    border-top-right-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
}

.fullscreen .modal {
    height: 100%;
}

@media (min-width: 768px) {
    .modalWrapper {
        --modal-content-horizontal-padding: var(--space-24);
    }

    .small.modalWrapper,
    .large.modalWrapper {
        align-items: center;
        padding: var(--screen-padding);
    }

    .small .modal,
    .large .modal {
        border-radius: var(--border-radius);
    }

    .small .modal {
        width: var(--modal-small-width);
    }

    .large .modal {
        width: var(--modal-large-width);
    }
}
