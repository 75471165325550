.header {
    --image-small-height: 160px;
    --image-large-height: 240px;
    --fab-padding: var(--space-16);

    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background-main);
}

/* Header variants */
.header:not(.contain) {
    height: var(--image-small-height);
    overflow: hidden;
}

@media (min-width: 768px) {
    .header.big:not(.contain) {
        height: var(--image-large-height);
    }
}

/* Image variants */
.image {
    position: absolute;
}

.image.contain {
    position: relative;
    max-width: 100%;
    height: auto;
}

/* Navigation buttons */
.closeButton {
    position: absolute;
    top: var(--fab-padding);
    right: var(--fab-padding);
}

.previousButton {
    position: absolute;
    top: var(--fab-padding);
    left: var(--fab-padding);
}

@media (min-width: 768px) {
    .previousButton {
        display: none;
    }
}
